import clsx from 'clsx';
import type { HTMLAttributes } from 'react';
import { memo } from 'react';

import styles from './divider.module.scss';

type DividerProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * @note For vertical orientation, you need to set the height explicitly
   */
  orientation?: 'horizontal' | 'vertical';
};

const Divider = memo(
  ({ className, orientation = 'horizontal', ...props }: DividerProps) => (
    <div
      className={clsx(styles.divider, className, {
        [styles.horizontal]: orientation === 'horizontal',
        [styles.vertical]: orientation === 'vertical',
      })}
      {...props}
    />
  )
);

export { Divider };
